<template>
    <!-- <el-header>
        头部
    </el-header> -->
    <div class="header">
        <div class="menu-btn">
            <el-button plain icon="el-icon-menu" size="mini" @click="collapseMenu"></el-button>
        </div>
        <div class="user-box">
            <div class="user">
                <img src="../../../assets/image/user.jpg" alt="" srcset="">
                <span>{{userName}}</span>
            </div>
            <div class="logout">
                <div class="item" @click="modifyPwd">修改密码</div>
                <div class="item" @click="logout">退出</div>
            </div>
        </div>
    </div>
      
    
</template>
<script>
    import {
        mapState
    } from 'vuex'
    import * as api from '@/config/api'
    export default {
        name: 'Header',
        data() {
            return {

            }
        },
        computed: {
            ...mapState({
                userName: state => state.userName,
            })
        },
        mounted() {

        },
        methods: {
            logout() {
                api.logout(res => {
                    this.$router.push({
                        path: '/login'
                    })
                })
            },
            modifyPwd() {
                this.$router.push({
                    path: '/modifyPwd'
                })
            },
            //控制左侧菜单是否折叠
            collapseMenu() {
                this.$store.commit('collapseMenu')
            },
        }
    }
</script>
<style lang="scss">
    .el-header {
        padding: 0;
    }
    
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #343a40
    }
    
    .menu-btn {
        width: 100px;
        /* display: flex; */
    }
    
    .user-box {
        text-align: left;
        min-width: 100px;
        height: 50px;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        color: #ffffff;
    }
    
    .user-box img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
    }
    
    .user-box:hover .logout {
        display: block;
    }
    
    .logout {
        background-color: #ffffff;
        width: 100px;
        /* height: 50px; */
        position: absolute;
        top: 50px;
        right: 6px;
        z-index: 1;
        border-radius: 5px;
        border: 1px solid #ededed;
        line-height: 50px;
        text-align: center;
        display: none;
        color: #000;
    }
    
    .logout .item {
        height: 40px;
        line-height: 40px;
    }
    
    .logout .item:hover {
        display: block;
        background-color: #f7f7f7;
    }
</style>