<template>
    <el-container>
        <!-- 导航 -->
        <el-aside width="auto">
          <Nav></Nav> 
        </el-aside>
        <!-- 导航结束 -->
        <el-container>
          <!-- 头部 -->
          <el-header>
            <Header></Header>
          </el-header>
          <!-- 头部结束 -->
            <el-main>
              <Tab></Tab>
              <keep-alive exclude="activitySnatchTreasureActivity,activitySnatchTreasureMdms,associationData,accountJurisdiction">
                <router-view></router-view>
              </keep-alive>
            </el-main>
            <Footer></Footer>
          </el-container>
          <!-- 底部 -->
         
          <!-- 底部结束 -->
    </el-container>
</template>
<script>
    import Header from '@/pages/public/index/header'
    import Nav from '@/pages/public/index/nav'
    import Footer from '@/pages/public/index/footer'
    import Tab from '@/pages/public/index/tab'
    import * as api from '@/config/api'
    export default {
        components: {
            Header,
            Tab,
            Nav,
            Footer,
        },
        mounted() {
            let Height = document.documentElement.clientHeight
                // document.getElementsByClassName('el-main')[0].style.height = Height - 120 + 'px'
        },
        methods: {}
    }
</script>
<style scoped>
    body {
        overflow: hidden;
    }
    
    .el-main {
        height: calc(100vh - 120px);
        background-color: #f4f6f9;
        padding: 0;
        overflow: scroll;
        overflow-x: hidden;
    }
</style>